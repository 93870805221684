<script setup lang="ts">
  const pageInfoStore = usePageInfoStore();
</script>

<template>
  <div class="not-found">
    <span class="not-found-title">Не нашли то, что искали?</span>
    <span class="not-found-text">Напишите или позвоните нам, мы подберём врача и запишем вас на приём</span>
    <div class="not-found-buttons">
      <NuxtLink target="_blank" external :href="pageInfoStore.getWhatsAppPhone" class="not-found-button">
        Написать в WhatsApp
      </NuxtLink>
      <NuxtLink :to="pageInfoStore.getLinkPhone" class="not-found-button">
        {{ pageInfoStore.getFormattedMainPhone }}
      </NuxtLink>
    </div>
  </div>
</template>

<style scoped lang="scss">
.not-found {
  display: flex;
  flex-direction: column;
  height: fit-content;
  width: 300px;
  padding: 24px;
  border-radius: 26px;
  background-color: white;
  box-shadow: 0px 6px 34px 0px rgba(0, 0, 0, 0.10);
  @media (max-width: 1023px) {
    width: 100%;
  }
}
.not-found-title {
  color: #000;
  font-family: 'Roboto';
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  margin-bottom: 8px;
}
.not-found-text {
  color: #262633;
  font-feature-settings: 'liga' off;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}
.not-found-buttons {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 24px;
}
.not-found-button {
  text-align: center;
  padding: 14px 16px;
  border-radius: 15px;
  background-color: #F2F3F6;
  color: #262633;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 125%;
  text-decoration: none;
}
</style>
