<script setup lang="ts">
  const { currentActiveTab } = storeToRefs(useNeurologistNavigationStore());
  const { showModal } = storeToRefs(useSearchPanelStore());

  const { y } = useWindowScroll();

  const zIndex = computed(() => {
    if (y.value === 0 && showModal.value === false) {
      return '99';
    }
    if (showModal.value) {
      return '99';
    } else {
      return '101';
    }
  });
</script>

<template>
  <div class="line-container" :style="{ zIndex: zIndex }">
    <div class="neurologist-nav main-container">
      <NuxtLink to="#info" class="nav-item" :class="{active: currentActiveTab === 'info'}">
        Инфо
      </NuxtLink>
      <NuxtLink to="#prices" class="nav-item" :class="{active: currentActiveTab === 'prices'}">
        Цены
      </NuxtLink>
      <NuxtLink to="#doctors" class="nav-item" :class="{active: currentActiveTab === 'doctors'}">
        Врачи
      </NuxtLink>
      <NuxtLink to="#preparation" class="nav-item" :class="{active: currentActiveTab === 'preparation'}">
        Подготовка
      </NuxtLink>
      <!-- <NuxtLink to="#questions" class="nav-item" :class="{active: currentActiveTab === 'questions'}">
        Вопросы и ответы
      </NuxtLink> -->
    </div>
  </div>
</template>

<style scoped lang="scss">
.line-container {
  border-bottom: 1px solid #E4E7EF;
  position: sticky;
  top: 90px;
  @media (max-width: 1023px) {
    top: 72px;
    background-color: white;
  }
}
.neurologist-nav {
  display: flex;
  gap: 24px;
  overflow-x: hidden;
  @media (max-width: 1023px) {
    gap: 20px;
  }
}
.nav-item {
  text-decoration: none;
  text-wrap: nowrap;
  padding-top: 16px;
  padding-bottom: 24px;
  cursor: pointer;
  color: #666E81 !important;
  font-feature-settings: 'liga' off;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.09px;
}
.active {
  border-bottom: 4px solid #2386FA;
  color: #262633 !important;
  transition: color .3s;
}
</style>
